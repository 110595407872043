import { FC } from "react"

export type BreadcrumbItem = {
  name: string
  href: string
}

type BreadcrumbProps = {
  items: BreadcrumbItem[]
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items }) => {
  const isCondensed = items.length > 3

  const renderMobileView = () => (
    <>
      <li className="whitespace-nowrap">
        <a href={items[0].href}>{items[0].name}</a>
      </li>
      <li className="whitespace-nowrap mx-2">/ ... /</li>
      <li className="whitespace-nowrap font-semibold">
        <span>{items[items.length - 1].name}</span>
      </li>
    </>
  )

  const renderDesktopView = () => (
    <>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1
        return (
          <li
            key={item.href}
            className={`whitespace-nowrap ${isLastItem ? "font-semibold" : ""}`}
          >
            {isLastItem ? (
              <span>{item.name}</span>
            ) : (
              <a href={item.href}>{item.name}</a>
            )}
            {!isLastItem && <span className="mx-2">/</span>}
          </li>
        )
      })}
    </>
  )

  return (
    <nav aria-label="breadcrumb" className="flex items-center">
      <div className="flex items-center p-0 m-0 font-montserrat text-gray9 overflow-x-auto">
        {isCondensed ? (
          <ol className="flex md:hidden p-0 m-0">{renderMobileView()}</ol>
        ) : null}

        <ol
          className={`items-center p-0 m-0 ${
            isCondensed ? "hidden md:flex" : "flex"
          }`}
        >
          {renderDesktopView()}
        </ol>
      </div>
    </nav>
  )
}

export default Breadcrumb
