import Image from "next/image"
import { FC } from "react"
import classNames from "classnames"
import { readImage } from "../../app/utils/read-image"
import { BlokImage } from "../../storyblok"
import PageNav, { NavigationType } from "../../storyblok/sections/PageNav"
import { PremiumHeroForm } from "./hero-form"
import Badge from "../../assets/images/all-driver-reviews/moved-badge.svg"
import { useGeoDataEffect } from "../../hooks/useGeoData"

export interface IPremiumHero {
  blok: {
    tagline: string
    title: string
    description: string
    buttonLink?: string
    buttonLabel?: string
    bgImage?: BlokImage
    bgSmImage?: BlokImage
    navItems?: NavigationType["navItems"]
    categories?: NavigationType["categories"]
    isNavHidden?: boolean
    ctaRoute?: string
    hasNavigation?: boolean
    showBadge?: boolean
    geoPostDescription?: string
    hasGeoCustomization?: boolean
  }
}
const PremiumHero: FC<IPremiumHero> = (props) => {
  const {
    tagline,
    title,
    description,
    buttonLabel,
    buttonLink,
    bgSmImage,
    bgImage,
    ctaRoute,
    navItems = [],
    categories = [],
    isNavHidden,
    hasNavigation,
    showBadge = false,
    hasGeoCustomization = false,
    geoPostDescription = "Hire top-rated transporters in {location}.",
  } = props.blok

  const geoDescription = useGeoDataEffect(geoPostDescription)

  return (
    <>
      {hasNavigation && (
        <PageNav
          noBlok
          route={ctaRoute || buttonLink}
          buttonLabel={buttonLabel}
          navigationData={{ navItems, categories, isNavHidden }}
          position=""
        />
      )}
      <section
        id="premium-hero"
        className={classNames("relative md:h-[685px]")}
      >
        <div className="absolute h-[385px] inset-0 z-1 md:h-full">
          {bgImage && (
            <Image
              className="hidden md:block"
              src={readImage(bgImage)}
              alt={bgImage.alt || "hero image"}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              priority
            />
          )}
          {bgSmImage && (
            <Image
              className="md:hidden"
              src={readImage(bgSmImage ?? bgImage)}
              alt={bgSmImage.alt || "hero image"}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          )}
          {showBadge && (
            <Image
              className="w-[96px] h-[96px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] absolute bottom-4 right-4 sm:bottom-10 md:bottom-[160px] md:right-10 z-10"
              src={Badge}
              alt={"100k pets transported"}
              width={120}
              height={120}
            />
          )}
        </div>

        <div className="absolute h-[385px] inset-0 bg-black bg-opacity-50 md:h-full"></div>

        <div className="h-[385px] md:h-full relative max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto px-6 lg:px-8 py-8">
          <div className="text-white font-merriweather max-w-3xl text-left">
            <p className="text-md font-light italic md:mt-10 lg:mt-20 mb-0">
              {tagline}
            </p>
            <h1 className="text-[26px] leading-[50px] sm:text-[42px] sm:leading-[72px] md:text-[56px] font-bold lg:text-[56px] mt-1 md:mt-4 mb-6">
              {title}
            </h1>
            <p
              id="PremiumHero-description"
              className="text-lg max-w-lg font-inter"
            >
              {description} {hasGeoCustomization ? geoDescription : ""}
            </p>
          </div>
        </div>
        <div className="relative lg:absolute lg:-bottom-24 max-w-7xl mx-auto md:flex justify-center bg-white rounded-1 md:h-[238px] px-6 md:px-5 lg:px-10 py-11 right-0 left-0">
          <PremiumHeroForm buttonLabel={buttonLabel} buttonLink={buttonLink} />
        </div>
      </section>
      <section className="lg:mb-10" />
    </>
  )
}
export default PremiumHero
