import React, { FC } from "react"
import { SbBlokData } from "@storyblok/react"
import Image from "next/image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

import { BlokImage } from ".."
import StarRating from "../../components/star-rating-v2"
import star from "../../assets/vector/star-brown.svg"
import { readImage } from "../../app/utils/read-image"

type Image = {
  src: string
  alt: string
}

interface Item {
  description: string
  rating: string
  image: BlokImage | Image
  distance: string
  cost: string
}

export interface IItem extends SbBlokData {
  items?: Item[]
  title?: string
  subTitle?: string
  coloredTitle?: string
}
const CardSetItem: FC<Item> = ({
  image,
  description,
  rating,
  distance,
  cost,
}) => {
  return (
    <article className="border bg-secondary-100 border-secondary-300 rounded-3xl md:flex gap-x-12 items-start mb-10 transition-opacity duration-500 ease-in-out p-4">
      <section className="mx-auto max-w-[354px] md:min-h-[300px] max-h-[554px]">
        <div className="mx-auto justify-center items-center">
          <Image
            className="object-cover w-full md:w-[318px] h-[333px] rounded-lg"
            src={readImage(image)}
            alt={image?.alt || ""}
            width={318}
            height={333}
          />
        </div>

        <div className="mt-7">
          <StarRating
            rating={parseInt(rating)}
            star={{ ...star, width: 16, height: 16 }}
          />
          <div className="text-2xl mb-2 mt-3 font-merriweather text-secondary-900">
            {description}
          </div>
          <p className={`text-gray14 mb-3 mt-2 text-base font-inter`}>
            {distance} miles
          </p>
          <p className="text-gray14 text-2xl font-merriweather">${cost}</p>
        </div>
      </section>
    </article>
  )
}

const CardSet = ({ blok }: { blok: IItem }) => {
  const { items, title, subTitle, coloredTitle } = blok
  return (
    <section className="max-w-[1334px] mx-auto px-4 pt-8 mt-10 md:pt-10 lg:mt-8">
      <p className="font-merriweather mb-6 text-3xl leading-[48px] lg:text-[40px] lg:leading-[72px] text-center md:px-18 mx-auto lg:max-w-[1118px]">
        {title}
        <span className="text-secondary-500"> {coloredTitle}</span>
      </p>
      <p className="font-inter mb-8 mx-auto text-center text-2xl">{subTitle}</p>
      <div className="px-2 lg:flex gap-8 items-center justify-center sm:max-w-md md:max-w-none mx-auto">
        {items?.map((item, index) => (
          <div key={index} className="md:max-lg:max-w-md lg:max-w-none mx-auto">
            <CardSetItem
              cost={item.cost}
              distance={item.distance}
              rating={item.rating}
              description={item.description}
              image={item.image}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
export default CardSet
