export const capitalizeEveryWord = (string) => {
  return string.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
    match.toUpperCase(),
  )
}

export const dumbSimplePluralize = (length, string) => {
  return length > 1 ? `${string}s` : string
}

export const removeDash = (string) => string.replace(/-/g, " ")

export const buildUrl = (baseUrl, path) => {
  return `${baseUrl.replace(/\/+$/, "")}/${path.replace(/^\/+/, "")}`
}

export const splitContentByTag = (content) => {
  const regex = /(<section>[\s\S]*?<\/section>)/g

  let match
  let lastIndex
  let h3Count = 0

  while ((match = regex.exec(content)) !== null && h3Count < 2) {
    h3Count++
    if (h3Count === 2) {
      lastIndex = match.index + match[0].length
    }
  }

  if (h3Count < 2) {
    return { firstPart: content, secondPart: "" }
  } else {
    const firstPart = content.substring(0, lastIndex)
    const secondPart = content.substring(lastIndex)
    return { firstPart, secondPart }
  }
}

export const redactNames = (name) => {
  const parts = name.split(" ")

  for (let i = 1; i < parts.length; i++) {
    parts[i] = parts[i][0] + "."
  }

  return parts.join(" ")
}
export const replaceValue = (text, variables) => {
  let updatedText = text
  Object.keys(variables).forEach((key) => {
    const placeholderPattern = new RegExp(`{${key}}`, "g")
    updatedText = updatedText.replace(placeholderPattern, variables[key])
  })
  return updatedText
}
