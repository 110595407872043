import { FC } from "react"
import Image from "next/image"
import { GoCheckCircle } from "react-icons/go"
import Link from "next/link"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface Props {
  blok: {
    stats: {
      title?: string
      text?: string
    }[]
    title?: string
    image1: BlokImage
    image2: BlokImage
    buttonLabel: string
    buttonLink: string
  }
}
const PremiumPriceTag: FC<Props> = (props) => {
  const {
    stats,
    image1,
    image2,
    buttonLabel,
    buttonLink,
    title = "Select Drivers and Transport Pets with Confidence",
  } = props.blok
  return (
    <section
      id="premium-stats"
      className="bg-white p-8 max-w-6xl mx-auto w-full mt-14 pt-4 mb-8 md:mb-20 md:pb-20 "
    >
      <h3 className="text-3xl md:text-4xl mb-10 font-merriweather leading-[60px] md:leading-[72px] text-left md:text-center">
        {title}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 lg:gap-20">
        <div className="order-2 md:order-1">
          <ul className="px-0 gap-8 mb-12">
            {stats.map((stat) => (
              <li
                key={stat.title?.trim()}
                className="text-xl font-inter flex mb-4 items-start"
              >
                <span className="text-gold pr-8">
                  <GoCheckCircle size={36} />
                </span>
                {stat.title}
              </li>
            ))}
          </ul>
          <Link
            href={buttonLink}
            className="block w-full bg-blue7 px-12 py-4 text-white hover:text-white no-underline hover:no-underline text-center md:inline uppercase"
          >
            {buttonLabel}
          </Link>
        </div>
        <div className="order-1 md:order-2">
          <div className="relative mb-24 pb-4 md:mb-0 md:pb-0 sm:flex justify-center md:block">
            <Image
              src={readImage(image1)}
              alt={image1.alt}
              className="rounded-lg w-[215px] h-[313px] md:w-[302px] md:h-[417px] md:absolute top-0"
              width={302}
              height={417}
              loading="lazy"
            />
            <Image
              src={readImage(image2)}
              alt={image2.alt}
              className="-full w-[215px] h-[216px] md:w-[288px] md:h-[288px] absolute top-40 left-28 md:left-20 md:top-[245px] xl:left-[150px] z-30"
              width={288}
              height={288}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PremiumPriceTag
