import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { HiOutlineCube } from "react-icons/hi"

import type { GoogleAddressData } from "../location"
import { generateFormQueryParams } from "../../services/links"

import { useGoogleMaps } from "../../app/hooks/use-google-maps"

import { PlaceInputPlaceholder } from "../location-placeholder"
import { PlaceInputAutocomplete } from "../location"
import { env } from "../../config"
import { Select } from "./select"
import {
  PawIcon,
  MotorcycleIcon,
  CarIcon,
  BoatIcon,
  FurnitureIcon,
  HomeIcon,
  TractorIcon,
  FreightIcon,
  BoxIcon,
} from "../svgs"

const shippingOptions = [
  {
    value: "animals",
    label: "Pets and animals",
    icon: <PawIcon />,
  },
  {
    value: "motorcycles",
    label: "Motorcycles",
    icon: <MotorcycleIcon />,
  },
  {
    value: "vehicles",
    label: "Vehicles",
    icon: <CarIcon />,
  },
  {
    value: "boats",
    label: "Boats and water vehicles",
    icon: <BoatIcon />,
  },
  {
    value: "household-items",
    label: "Household items",
    icon: <FurnitureIcon />,
  },
  {
    value: "apartment-home-moves",
    label: "Apartment and home moves",
    icon: <HomeIcon />,
  },
  {
    value: "heavy-equipment",
    label: "Heavy equipment and industrial",
    icon: <TractorIcon />,
  },
  {
    value: "freight",
    label: "Freight",
    icon: <FreightIcon />,
  },
  {
    value: "others",
    label: "All other items",
    icon: <BoxIcon />,
  },
]

const addressSchema = z.object({
  formatted_address: z.string(),
  city: z.string(),
  state_code: z.string(),
  lat: z.number(),
  lng: z.number(),
  zip: z.string().nullable(),
})

const transportationSchema = z.object({
  pickup: addressSchema.nullable(),
  delivery: addressSchema.nullable(),
  category: z.string({
    required_error: "Category is required",
    invalid_type_error: "Category is required",
  }),
})
export type Props = {
  buttonLink?: string
  buttonLabel?: string
}
export const PremiumHeroForm: React.FC<Props> = (props) => {
  const { buttonLabel, buttonLink } = props
  const APP_HOST = env("APP_HOST")
  const LINK = buttonLink ?? "/shipments/new"
  const [focusedField, setFocusedField] = React.useState<
    "pickup" | "delivery" | null
  >(null)

  const { handleSubmit, control, setFocus } = useForm<{
    pickup: null | GoogleAddressData
    delivery: null | GoogleAddressData

    category: null | string
  }>({
    defaultValues: {
      pickup: null,
      delivery: null,
      category: null,
    },
    resolver: zodResolver(transportationSchema),
  })

  const { mapsLoaded, loadMaps } = useGoogleMaps()

  React.useEffect(() => {
    if (mapsLoaded && focusedField != null) {
      setFocus(focusedField)
    }
  }, [mapsLoaded, focusedField, setFocus])

  const onSubmit = handleSubmit((data) => {
    let queryParams: Record<string, string | number> = {}

    if (data.pickup != null) {
      queryParams.pickupFA = data.pickup.formatted_address
      queryParams.pickupSC = data.pickup.state_code
      queryParams.pickupCt = data.pickup.city
      queryParams.pickupLat = data.pickup.lat
      queryParams.pickupLng = data.pickup.lng
      queryParams.pickupZip = data.pickup.zip ?? ""
    }

    if (data.delivery != null) {
      queryParams.deliveryFA = data.delivery.formatted_address
      queryParams.deliverySC = data.delivery.state_code
      queryParams.deliveryCt = data.delivery.city
      queryParams.deliveryLat = data.delivery.lat
      queryParams.deliveryLng = data.delivery.lng
      queryParams.deliveryZip = data.delivery.zip ?? ""
    }
    const formQueryParams = generateFormQueryParams({
      query: queryParams,
    })

    if (data.category) {
      const route = `${APP_HOST}/shipments/new/${data.category}${formQueryParams}`

      window.location.assign(route)
      return
    }

    window.location.assign(`${APP_HOST}${LINK}${formQueryParams}`)
  })

  return (
    <form
      className="grid grid-cols-1 gap-y-6 md:grid-cols-4 sm:gap-x-4 md:gap-x-6 lg:gap-x-8"
      onSubmit={onSubmit}
    >
      <div>
        <Controller
          control={control}
          name="category"
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return (
              <div>
                <Select
                  value={field.value}
                  ref={field.ref}
                  id="category"
                  onChange={field.onChange}
                  placeholder=""
                  label="What are you shipping?"
                  items={shippingOptions}
                  defaultIcon={
                    <HiOutlineCube size={24} className="w-6 text-gold" />
                  }
                  error={error}
                />
              </div>
            )
          }}
        />
      </div>
      <div>
        <Controller
          name="pickup"
          control={control}
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return mapsLoaded ? (
              <PlaceInputAutocomplete
                value={field.value}
                onChange={field.onChange}
                placeholder="Pickup location"
                type="pickup"
                error={error}
                id="pickup-location"
                ref={field.ref}
              />
            ) : (
              <PlaceInputPlaceholder
                value={field.value}
                onChange={field.onChange}
                placeholder="Pickup location"
                type="pickup"
                id="pickup-location"
                onFocus={() => {
                  loadMaps()
                  setFocusedField("pickup")
                }}
              />
            )
          }}
        />
      </div>
      <div>
        <Controller
          name="delivery"
          control={control}
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return mapsLoaded ? (
              <PlaceInputAutocomplete
                value={field.value}
                onChange={field.onChange}
                placeholder="Delivery location"
                type="dropoff"
                error={error}
                id="dropoff-location"
                ref={field.ref}
              />
            ) : (
              <PlaceInputPlaceholder
                value={field.value}
                onChange={field.onChange}
                placeholder="Delivery location"
                type="dropoff"
                id="dropoff-location"
                onFocus={() => {
                  loadMaps()
                  setFocusedField("delivery")
                }}
              />
            )
          }}
        />
      </div>

      <div>
        <button
          className="w-full lg:px-2 xl:px-0.5 py-5 capitalize text-center bg-blue7 hover:bg-blue-700 mt-10 text-white text-md"
          onClick={onSubmit}
        >
          {buttonLabel}
        </button>
      </div>
    </form>
  )
}
